import { cloneDeep } from "lodash";
import * as a from "actiontypes";

export const calendarInitial = {
  calendar: {},
  attendee_calendar: {},
  alert: { msg: "", variant: "info", hidden: true },
  schedule: {},
  notification_center: false,
  cancelInvite: { slotData: null, save: false }
};

const calendar = (state = calendarInitial, action) => {
  switch (action.type) {

    case a.GET_ATTENDEE_CALENDAR:
      return { ...state, attendee_calendar: action.payload };

    case a.ADD_INVITE_ATTENDEE_CALENDAR: {
      let attendeeCalendar = cloneDeep(state.attendee_calendar)
      if (attendeeCalendar) {
        const { slot, invite, me } = action.payload
        const user = (({ first_name, last_name, chat_user_id: user_id }) => ({ first_name, last_name, user_id }))(me)
        const { sent_invites, ...newSlot } = slot
        const receivedInvite = invite
        receivedInvite.slot = newSlot
        receivedInvite.user = user
        receivedInvite.invitee_status = newSlot.status
        receivedInvite.inviter_id = me.chat_user_id
        attendeeCalendar.received_invites.push(receivedInvite)
        return { ...state, attendee_calendar: attendeeCalendar };
      } else {
        return state
      }
    }
    case a.REMOVE_INVITE_FROM_ATTENDEE_CALENDAR: {
      let attendeeCalendar = cloneDeep(state.attendee_calendar)
      const { isReceived, data = {}, myChatID } = action.payload
      if (isReceived) {
        const { sent_invites = [] } = data
        const { slot = {} } = sent_invites[0]
        const filteredInvites = attendeeCalendar.received_invites.filter(inv => !(inv.slot.start_time === slot.start_time && inv.inviter_id === myChatID && inv.slot.id === slot.id))
        attendeeCalendar.received_invites = filteredInvites
        return { ...state, attendee_calendar: attendeeCalendar }
      }
      return state
    }
    case a.GET_CALENDAR:
      return { ...state, calendar: action.payload };

    case a.GET_SCHEDULE:
      return { ...state, schedule: action.payload };

    case a.CREATE_SLOT: {
      let clonedSlots = cloneDeep(state.calendar.slots)
      let newSlot = action.payload.slot
      newSlot.sent_invites = []
      newSlot.sent_invites.push(action.payload.inv)
      clonedSlots.push(newSlot)
      return { ...state, calendar: { ...state.calendar, slots: clonedSlots } };
    }

    case a.CREATE_AVAILABLE_SLOT: {
      let newSlot = action.payload
      let newState = cloneDeep(state)
      newState.calendar.slots.push(newSlot)
      return { ...state, ...newState };
    }

    case a.DELETE_SLOT: {
      let clonedState = cloneDeep(state)
      let updatedState = cloneDeep(state)

      if (action.payload.isDuplicate) {
        updatedState.calendar.received_invites = clonedState.calendar.received_invites.filter(invite => invite.slot.id !== action.payload.id)
      } else {
        updatedState.calendar.slots = clonedState.calendar.slots.filter(slot => parseInt(slot.id) !== parseInt(action.payload.id))
      }

      return { ...state, ...updatedState };
    }

    case a.HANDLE_INVITE:
      let invite = action.payload
      let updatedInvites = state.calendar.received_invites.filter(i => i.slot.id !== invite.slot.id)
      updatedInvites.push(invite)
      state.calendar.received_invites = updatedInvites
      return { ...state };

    case a.HANDLE_CANCEL_RECEIVED_INVITE:
      let cancelledID = action.payload
      let filteredInvites = state.calendar.received_invites.filter(i => i.slot.id !== cancelledID)
      state.calendar.received_invites = filteredInvites
      return { ...state };

    case a.CANCEL_INVITE:
      let slot = action.payload
      let updatedSlots = state.calendar.slots.filter(s => s.id !== slot.id)
      state.calendar.slots = updatedSlots
      return { ...state };

    case a.HANDLE_INVITE_RESPONSE: {
      let clonedState = cloneDeep(state)
      let incomingInvite = action.payload.invite
      const isMine = action.payload.isMine
      let updatedInvites = []
      let updatedSlots = []
      const isAccepted = incomingInvite.status === 'accepted'
      const isPending = incomingInvite.status === 'pending'

      if (!isMine) {
        if (isAccepted) {
          updatedInvites = clonedState.calendar.received_invites.filter(inv => inv.inviter_id === incomingInvite.inviter_id && inv.slot.id !== incomingInvite.slot.id)
          updatedInvites.push(incomingInvite)
        } else if (isPending) {
          updatedInvites = clonedState.calendar.received_invites
          updatedInvites.push(incomingInvite)
        } else {
          updatedInvites = clonedState.calendar.received_invites.filter(inv => inv.inviter_id === incomingInvite.inviter_id && inv.slot.id !== incomingInvite.slot.id)
        }
        updatedSlots = clonedState.calendar.slots
      } else {
        updatedSlots = clonedState.calendar.slots.filter(s => s.id !== incomingInvite.slot.id)
        if (isAccepted) {
          let updatedSlot = clonedState.calendar.slots.find(s => s.id === incomingInvite.slot.id)
          if (updatedSlot) {
            updatedSlot.sent_invites[0].status = 'accepted'
            updatedSlot.sent_invites[0].inviter_id = incomingInvite.inviter_id
            updatedSlots.push(updatedSlot)
          }
        }
        updatedInvites = clonedState.calendar.slots
      }



      return { ...state, calendar: { ...state.calendar, received_invites: updatedInvites, slots: updatedSlots } };
    }
    case a.ADD_FAVORITE:
      let favES = action.payload
      state.calendar.event_timeslots.push(favES)
      return { ...state };

    case a.DELETE_FAVORITE:
      const event_timeslots = state.calendar.event_timeslots.filter(ets => ets.id !== action.payload)
      state.calendar.event_timeslots = event_timeslots
      return { ...state };

    case a.SET_ALERT:
      return { ...state, alert: action.payload };

    case a.SET_CALENDAR_VISIBILITY:
      state.calendar.visibility = action.payload
      return { ...state };

    case a.HANDLE_NOTIFICATION_CENTER: {
      const isOpen = action.payload

      return { ...state, notification_center: isOpen };
    }

    case a.HANDLE_CANCEL_INVITE: {
      return { ...state, cancelInvite: action.payload };
    }
    default:
      return state;
  }
};
export default calendar;
