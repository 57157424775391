/** @format */

import { Localized } from "@fluent/react";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import * as a from "actiontypes";
import { connect } from "react-redux";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const mapDispatchToProps = dispatch => ({
	handleSidebar: payload => dispatch({ type: a.HANDLE_SIDEBAR, payload }),
});

const mapStateToProps = state => ({
	sidebar: state.components.sidebar,
});

const EventTimeSlot = ({ slot, handleSidebar, sidebar }) => {
	const [isSlotTooLong, setIsSlotTooLong] = useState("");
	const slotCardRef = useRef(null);
	const { generate } = useGenerateMultilingualData();
	useEffect(() => {
		if (moment(slot.end_time).diff(moment(slot.start_time), "minutes") > 500) {
			setIsSlotTooLong("long-slot");
		}
	}, [slot]);
	const handleSidebarClick = e => {
		e.stopPropagation();
		if (sidebar.isOpen && sidebar.slotRef?.current === slotCardRef.current) {
			handleSidebar({ isOpen: false, slot: null, slotRef: null });
		} else {
			handleSidebar({ isOpen: true, slot, slotRef: slotCardRef });
		}
	};
	const displayName = generate(slot.displayName, slot.name);
	return (
		<div className="event-timeslot-card-wrapper">
			<Card
				ref={slotCardRef}
				className={`event-timeslot-card ${isSlotTooLong}`}
				onClick={e => handleSidebarClick(e)}
			>
				<Card.Body>
					<Card.Title className="event-timeslot-card-title">
						<strong>
							{displayName.length > 100 &&
							moment(slot.end_time).diff(moment(slot.start_time), "minutes") <= 15
								? `${displayName.substring(0, 97)}...`
								: displayName}
						</strong>
					</Card.Title>
					<Card.Text className="event-timeslot-time">
						{`${moment(slot.start_time).format("HH:mm")} - ${moment(slot.end_time).format("HH:mm")}`}
					</Card.Text>

					<Localized id="click-to-view-details">
						<Card.Subtitle className="click-to-view-details text-muted mt-1">
							Click to view details
						</Card.Subtitle>
					</Localized>
				</Card.Body>
			</Card>
		</div>
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(EventTimeSlot);
