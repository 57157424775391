import { useState } from "react"
import { Card } from "react-bootstrap"
import { useEffect } from "react"
import { connect } from "react-redux"
import { Localized } from "@fluent/react"
import { GenericTooltip } from 'components/ToolTip/Tooltip';

const mapStateToProps = state => ({
    displayType: state.displayType
})

const ScheduleCard = (props) => {
    const [ startTimeOffset, setStartTimeOffset ] = useState(0)
    const [ scheduleCardHeight, setScheduleCardHeight ] = useState(0)
    const { schedule = {}, scheduleStartTime, handleViewSchedule, displayType = "desktop" } = props
    const { program = {}, name } = schedule
    const { duration, end, start } = program

    useEffect(() => {
        const programDuration = schedule.program.duration < 15 ? 15 : schedule.program.duration
        let cardHeight = (programDuration / 60) * 70
        setScheduleCardHeight(cardHeight)
        let minutesOffset = (schedule.program.start.diff(scheduleStartTime, 'minutes') / 60) * 70
        setStartTimeOffset(minutesOffset)
    }, [scheduleStartTime, schedule])

    const generateScheduleTitle = () => {
      if (duration <= 120 && name.length >= 20) return `${name.substring(0, 15)}...`
      else if (name.length <= 90) return name
      else return `${name.substring(0, 87)}...`
    }
    
    return (
        <div className={`schedule-card${displayType === 'mobile' ? '-mobile' : ''}-wrapper`}>
            <Card className={`schedule-card${displayType === 'mobile' ? '-' + displayType : ''}`} style={{ height: `${scheduleCardHeight}px`, marginTop: `${startTimeOffset}px` }} onClick={() => handleViewSchedule(schedule, false)}>
                <Card.Body style={duration < 30 ? { padding: 0 } : {}}>
                  <GenericTooltip text={name} hide="100" orientation="right">
                    <Card.Title className="schedule-card-title">
                      {generateScheduleTitle()}
                    </Card.Title>
                  </GenericTooltip>
                    {
                        duration >= 60
                        &&
                        <>
                            <Card.Text className="schedule-card-time">
                                {`${start.format('HH:mm')} - ${end.format('HH:mm')}`}
                            </Card.Text>
                            <Localized id="click-to-view-details">
                                <Card.Subtitle className="click-to-view-details-schedule text-muted mt-1">Click to view details</Card.Subtitle>
                            </Localized>
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}

export default connect(mapStateToProps)(ScheduleCard)