/** @format */

import { useContext } from "react";
import "./styles.scss";
import sanitizeHtml from "sanitize-html";
import parse from "html-react-parser";
import { APP_PATHS, WHITE_LIST_HTML } from "constants.js";
import { NavLink } from "react-router-dom";
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import { SettingContext } from "containers/VirtualEvent/contexts";
import { getDefaultOrAnyContent, useCurrentLanguage } from "util/localization";
import * as a from "actiontypes";
import { useDispatch } from "react-redux";

const getElementContent = (element, elementList, language, languageOptions, isEditor) => {
	const current = elementList.find(e => e.name === element);
	let retval = current?.content;
	if (element !== "Logo" && element !== "Background") {
		retval = getDefaultOrAnyContent(current, language, languageOptions, isEditor);
	}
	return retval ?? "";
};

const generateButton = (elements, slug, queryString, language, languageOptions, isEditor) => {
	const element = elements.find(e => e.name === "Link Button");
	const link = element?.actions?.["link"];
	const { background, border } = element?.properties;
	const backgroundStyle = background
		? {
				backgroundColor: background.color,
				backgroundImage: background.image,
				backgroundSize: background.size,
				backgroundRepeat: background.repeat,
				backgroundPosition: background.position
		  }
		: {};

	const borderStyle = border
		? {
				border: border?.border,
				borderRadius: border?.borderRadius
		  }
		: {};

	const buttonStyle = { ...backgroundStyle, ...borderStyle };
	let buttonInner = (
		<div className="simple-card-button" style={buttonStyle}>
			{parse(
				sanitizeHtml(
					getElementContent("Link Button", elements, language, languageOptions, isEditor),
					WHITE_LIST_HTML
				)
			)}
		</div>
	);
	return generateComponentWithLink(link, buttonInner, queryString, slug, "button");
};

const generateComponentWithLink = (link, element, queryString, slug, elemType) => {
	let component;
	if (link?.type === "inner") {
		const innerLink = APP_PATHS.virtual + "/" + slug + "/" + link.link + queryString;
		component = (
			<NavLink className={`simple-card-${elemType}-link`} to={innerLink}>
				{element}
			</NavLink>
		);
	} else if (link?.type === "outer") {
		component = (
			<a className={`simple-card-${elemType}-link`} target="_blank" rel="noreferrer" href={link.link}>
				{element}
			</a>
		);
	} else {
		component = element;
	}
	return component;
};

const SimpleCard = props => {
	const { elements, slug, properties } = props;
	const { languages = ["en", "fi"], isEditor, setActiveItem } = useContext(SettingContext);
	const dispatch = useDispatch();
	const languageOptions = languages;
	const lang = useCurrentLanguage(isEditor);
	const queryString = useAccessKey();
	const buttonComponent = generateButton(elements, slug, queryString, lang, languageOptions, isEditor);
	const titleElement = elements.find(e => e.name === "Title");
	const titleDropShadow = titleElement?.properties?.dropShadow;
	const titleStyle = { filter: titleDropShadow };
	const titleLink = titleElement?.actions?.link;

	const handleTitleDoubleClick = () => {
		if (titleElement && isEditor) {
			setActiveItem(titleElement);
			dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: titleElement.renderType, show: true } });
		}
	};

	let titleInnerComponent = (
		<p className="card-title" onDoubleClick={handleTitleDoubleClick} style={titleStyle}>
			{parse(
				sanitizeHtml(getElementContent("Title", elements, lang, languageOptions, isEditor), WHITE_LIST_HTML)
			)}
		</p>
	);

	return (
		<>
			<div
				className="simple-card"
				style={{
					backgroundImage: `url(${getElementContent(
						"Background",
						elements,
						lang,
						languageOptions,
						isEditor
					)})`
				}}
			>
				<div className="simple-card-header-container">
					{generateComponentWithLink(titleLink, titleInnerComponent, queryString, slug, "title")}
				</div>
				<div className="simple-card-bottom-overlay">{buttonComponent}</div>
			</div>
		</>
	);
};

export default SimpleCard;
