/** @format */

import { useEffect, useMemo, useState } from "react";
import { AUTH_HEADERS } from "util/api";
import { HOST, WHITE_LIST_HTML } from "constants.js";
import "./performers.scss";
import { Localized, useLocalization } from "@fluent/react";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import HeaderModal from "headingModal/modal";
import { connect } from "react-redux";
import { Spoiler, Accordion } from "@mantine/core";
import { useFetchPerformers } from "./apiCalls";
import { LivetoLoader } from "components/icons";
import { useGenerateMultilingualData } from "./helpers";

const mapStateToProps = state => ({
	close_modals: state.close_modals,
});

const Performers = props => {
	const { showPerformers, setShowPerformers, slug, close_modals } = props;
	const [state, getPerformers, performerLists] = useFetchPerformers(slug);

	const { generate } = useGenerateMultilingualData();

	function close() {
		setShowPerformers(false);
	}

	useEffect(() => {
		if (close_modals) {
			close();
		}
	}, [close_modals]); // eslint-disable-line react-hooks/exhaustive-deps

	const openAccordionItem = performerLists.length === 1 ? { defaultValue: `${performerLists[0].id}` } : {};

	return (
		<HeaderModal
			header={true}
			showModal={showPerformers}
			setShowModal={close}
			id="performers"
			headingText={<Localized id="performers-title">{"Performers"}</Localized>}
		>
			{state.loading ? (
				<LivetoLoader />
			) : (
				<div id="performers-list">
					{performerLists.length === 0 ? (
						<div>
							<h2 style={{ color: "#fff" }}>
								<Localized id="no-performers">No performers defined to this event</Localized>
							</h2>
						</div>
					) : (
						<Accordion className="performerlist-accordion" iconPosition="right" {...openAccordionItem}>
							{performerLists.map(list => {
								const diplayTitle = generate(list.title);
								return (
									<Accordion.Item value={`${list.id}`}>
										<Accordion.Control>{diplayTitle}</Accordion.Control>
										<Accordion.Panel>
											<PerformerList {...list} />
										</Accordion.Panel>
									</Accordion.Item>
								);
							})}
						</Accordion>
					)}
				</div>
			)}
		</HeaderModal>
	);
};

const PerformerList = ({ title, performers = [] }) => {
	return (
		<div className="performerlist">
			{performers.map(p => (
				<PerformerCard {...p} />
			))}
		</div>
	);
};

const PerformerCard = ({ name = {}, image, title = {}, url, description = {} }) => {
	const { l10n } = useLocalization();
	const { generate, generateHtml } = useGenerateMultilingualData();

	const displayName = generate(name);
	const displayTitle = generate(title);
	const displayDesc = generateHtml(description);
	return (
		<div className="performer-card" key={displayName}>
			<div className="performer-image" style={{ backgroundImage: `url("${image ? image : ""}")` }}></div>
			<div className="performer-information">
				<p className="performer-name">{displayName}</p>
				<p className="performer-title">{displayTitle}</p>
				{url && (
					<a className="performer-link" href={url} target="_blank" rel="noreferrer">
						{url}
					</a>
				)}
				<Spoiler
					maxHeight={200}
					showLabel={l10n.getString("virtual-event-show-more-button", null, "Show more")}
					hideLabel={l10n.getString("virtual-event-hide-button", null, "Hide")}
				>
					<p className="performer-description">{displayDesc}</p>
				</Spoiler>
			</div>
		</div>
	);
};

export default connect(mapStateToProps)(Performers);
