import { Localized } from "@fluent/react"
import { IconCalendar } from "components/icons"
import { IconTimes } from "components/icons"
import { IconArrowLeftBack } from "components/icons"
import { GenericTooltip } from "components/ToolTip/Tooltip"
import moment from "moment"
import { Row, Form } from "react-bootstrap"
import { useDispatch } from "react-redux";

const dateLabel = (date) => {
    const convertedDate = moment(date, 'DD.MM.YYYY')
    const isBefore = moment(convertedDate).isBefore(moment(), 'year')
    const isAfter = moment(convertedDate).isAfter(moment(), 'year')
    if (isBefore || isAfter) return date
    else return convertedDate.format('DD.MM.')
}

const DateSelector = (props) => {
    const { dates = [], activeDate, setActiveDate } = props
    return <div className="virtual-event-schedule-selector-container">
        <Form.Control as="select" className="schedule-selector" onChange={e => setActiveDate(e.target.value)} value={activeDate}>
            {dates.map(date => (<option className="date-option" value={date} label={dateLabel(date)} key={'option' + date}>
                {date}
            </option>))}
        </Form.Control>
    </div>
}

export const ScheduleNav = (props) => {
    const { content, handleContent, schedule = {}, setActiveDate, scheduleInfoText, handleCloseSchedule, activeDate } = props
    const { dates = [] } = schedule
    const dispatch = useDispatch()

    const handleBackButtonClick = () => {
        handleContent('program')
        dispatch({ type: 'HANDLE_SIDEBAR', payload: { slot: null, isOpen: false, slotRef: null } })
    }

    return (
        <div className="modal-schedule-header">
            <Row className="text-center justify-content-center">
                {(content === 'program' || content === 'single-schedule-day') ?
                    <Localized id='calendar-navbar-schedule-link'>
                        <h2 className='mx-2 schedule-header-link'>Event Schedules</h2>
                    </Localized>
                    : content === 'single-schedule' ?
                        <div className="d-flex cursor-pointer" onClick={handleBackButtonClick}>
                            <IconArrowLeftBack size={30} className='color-liveto m-auto' />
                            <Localized id='back-button-navbar-schedule-link'>
                                <h5 className='mx-2 schedule-header-link active-header-link' >{`Back to event schedules`}</h5>
                            </Localized>
                        </div> : ""}
            </Row>

            <div className="right-section">
                {(content === 'program' || content === 'single-schedule-day')
                    &&
                    <div className="virtual-event-schedule-selector-wrapper">
                        <div className="date-selector-label-wrapper">
                            <Localized id="date-selector-label">
                                <div className="date-selector-label">Program date</div>
                            </Localized>
                        </div>
                        <DateSelector dates={dates} activeDate={activeDate} setActiveDate={setActiveDate} />
                    </div>}
                <div className="close-button-tooltip-container">
                    <GenericTooltip text={scheduleInfoText}>
                        <div className="my-auto schedule-info-icon"><b>i</b></div>
                    </GenericTooltip>
                    <button className="close-virtual-schedule-button" onClick={handleCloseSchedule}><IconTimes /></button>
                </div>
            </div>

        </div>
    )
}

export const ScheduleNavMobile = (props) => {
    const { content, handleContent, schedule = {}, setActiveDate, scheduleInfoText, handleCloseSchedule, activeDate } = props
    const { dates = [] } = schedule
    const dispatch = useDispatch()

    const handleBackButtonClick = () => {
        handleContent('program')
        dispatch({ type: 'HANDLE_SIDEBAR', payload: { slot: null, isOpen: false, slotRef: null } })
    }
    return (
        <div className="modal-schedule-header-mobile">
            {(content === 'program' || content === 'single-schedule-day') ?
                <>
                    <IconCalendar className="schedule-icon" size="25" />
                    <DateSelector dates={dates} activeDate={activeDate} setActiveDate={setActiveDate} />
                </> : content === 'single-schedule' ?
                    <div className="d-flex">
                        <IconArrowLeftBack size={30} className='color-liveto m-auto' onClick={handleBackButtonClick} />
                    </div> : ""}
            <div className="close-button-tooltip-container">
                <GenericTooltip text={scheduleInfoText}>
                    <div className="my-auto schedule-info-icon"><b>i</b></div>
                </GenericTooltip>
                <button className="close-virtual-schedule-button-mobile" onClick={handleCloseSchedule}><IconTimes /></button>
            </div>
        </div>
    )
}

export const CalendarNav = (props) => {
    const { scheduleInfoText, handleCloseSchedule } = props
    return (
        <div className="modal-calendar-header">
            <Row className="modal-calendar-header-inner">
                <Row className="text-center justify-content-center">
                    <Localized id='calendar-navbar-mycal-link'>
                        <h2 className='calendar-header-link'>My Calendar</h2>
                    </Localized>
                </Row>
            </Row>

            <div className="close-info-calendar-wrapper">
                <GenericTooltip text={scheduleInfoText}>
                    <TooltipInfoButton />
                </GenericTooltip>
                <button className="close-virtual-calendar-button" onClick={handleCloseSchedule}><IconTimes /></button>
            </div>
        </div>
    )
}

export const TooltipInfoButton = () => {
    return <div className='tooltip-info-button'><div className="tooltip-info-icon"><b>i</b></div></div>
}