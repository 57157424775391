/** @format */

import { useEffect, useState } from "react";
import moment from "moment";
import "./css/calendar.scss";
import { Modal } from "react-bootstrap";
import Program from "./program";
import { Localized } from "@fluent/react";
import { connect } from "react-redux";
import { useFetchSchedules } from "./apiCalls";
import SingleScheduleView from "./components/SingleScheduleView";
import { ScheduleNav, ScheduleNavMobile } from "./components/ScheduleNav";

const mapStateToProps = state => ({
	schedule: state.schedule.schedule,
	displayType: state.displayType,
	close_modals: state.close_modals,
});

const defineActiveDate = (schedule = {}) => {
	if (!schedule.dates) return null;
	const { dates = [] } = schedule;
	const dateFormat = "DD.MM.YYYY";
	const today = dates.find(d => moment(d, dateFormat).isSame(moment(), "day"));
	let closestDate;
	for (let i = 0; i < dates.length; i++) {
		const date = moment(dates[i], dateFormat);
		if (dates[i - 1] && date.isBefore(moment(), "day")) closestDate = dates[i];
		else if (dates[i - 1] && date.isAfter(moment(), "day")) {
			if (date.isBefore(moment(closestDate, dateFormat))) {
				closestDate = dates[i];
				break;
			}
		} else {
			closestDate = dates[i];
		}
	}
	const dateToReturn = today ? today : closestDate;
	return dateToReturn;
};

const Schedule = props => {
	const { showSchedule, setShowSchedule, slug, schedule = {}, displayType, close_modals } = props;
	const [activeDate, setActiveDate] = useState(null);
	const [activeSchedule, setActiveSchedule] = useState(null);
	const [content, setContent] = useState("program");
	const [getSchedule, setGetSchedule] = useState(true);
	const [scheduleLoading] = useFetchSchedules(slug, getSchedule);
	const [scheduleInfoText] = useState(
		<Localized id="schedule-info-text">
			<div>The times in this program are displayed according to your own time zone</div>
		</Localized>
	);
	const [activeDaySchedules, setActiveDaySchedules] = useState(null);

	useEffect(() => {
		setActiveDate(defineActiveDate(schedule));
	}, [JSON.stringify(schedule.dates)]);

	useEffect(() => {
		if (!showSchedule) {
			setGetSchedule(false);
		} else {
			setGetSchedule(true);
		}
	}, [showSchedule]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (activeDate) {
			let activeSchedules = [];
			schedule.schedules.forEach(s => {
				s.days.forEach(day => {
					if (day[activeDate]) {
						const { days, ...scheHelper } = s;
						scheHelper.program = day[activeDate];
						activeSchedules.push(scheHelper);
					}
				});
			});
			if (activeSchedules.length === 1) {
				setActiveSchedule(activeSchedules[0]);
				setContent("single-schedule-day");
			} else {
				setActiveSchedule(activeSchedules[0]);
				setContent("program");
			}
			setActiveDaySchedules(activeSchedules);
		}
	}, [activeDate]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (close_modals) {
			close();
		}
	}, [close_modals]); // eslint-disable-line react-hooks/exhaustive-deps

	function close() {
		setShowSchedule(false);
	}

	const handleCloseSchedule = () => {
		setShowSchedule(false);
	};

	const handleContent = content => {
		if (content === "program") {
			setGetSchedule(true);
		} else {
			setGetSchedule(false);
		}
		setContent(content);
	};

	const handleViewSchedule = schedule => {
		setContent("single-schedule");
		setActiveSchedule(schedule);
	};

	return (
		<Modal
			className="p-0"
			animation={false}
			centered
			show={showSchedule}
			onHide={close}
			backdropClassName="schedule-modal-backdrop"
			dialogClassName="schedule-modal-dialog"
			contentClassName="virtual-event-schedule"
		>
			{displayType === "mobile" ? (
				<ScheduleNavMobile
					activeDate={activeDate}
					content={content}
					handleContent={handleContent}
					schedule={schedule}
					setActiveDate={setActiveDate}
					scheduleInfoText={scheduleInfoText}
					handleCloseSchedule={handleCloseSchedule}
				/>
			) : (
				<ScheduleNav
					activeDate={activeDate}
					content={content}
					handleContent={handleContent}
					schedule={schedule}
					setActiveDate={setActiveDate}
					scheduleInfoText={scheduleInfoText}
					handleCloseSchedule={handleCloseSchedule}
				/>
			)}

			<div className="modal-inner-content overflow-auto">
				{content === "program" ? (
					<Program
						selectedDate={activeDate}
						scheduleLoading={scheduleLoading}
						handleViewSchedule={handleViewSchedule}
						displayMode={displayType}
						setContent={setContent}
						activeSchedules={activeDaySchedules}
						activeSchedule={activeSchedule}
					/>
				) : content === "single-schedule" || content === "single-schedule-day" ? (
					activeSchedule && <SingleScheduleView schedule={activeSchedule} close={close} />
				) : (
					<Localized id="something-went-wrong">
						<div>Something went wrong...</div>
					</Localized>
				)}
			</div>
		</Modal>
	);
};

export default connect(mapStateToProps)(Schedule);
