import { useContext } from 'react';
import './styles.scss';
import sanitizeHtml from "sanitize-html"
import parse from "html-react-parser"
import { APP_PATHS, WHITE_LIST_HTML } from "constants.js";
import { IconArrowsRight } from 'components/icons';
import { NavLink } from 'react-router-dom';
import { useAccessKey } from 'containers/VirtualEvent/editorView/apiCalls';
import { SettingContext } from 'containers/VirtualEvent/contexts';
import { getDefaultOrAnyContent, useCurrentLanguage } from 'util/localization';
import * as a from 'actiontypes'
import { useDispatch } from 'react-redux';

const getElementContent = (element, elementList, language, languageOptions, isEditor) => {
  const current = elementList.find(e => e.name === element);
  let retval = current?.content;
  if (element !== 'Logo' && element !== 'Background') {
    retval = getDefaultOrAnyContent(current, language, languageOptions, isEditor);
  }
  return retval ?? '';
}

const generateButton = (elements, slug, queryString, language, languageOptions, isEditor) => {

  const element = elements.find(e => e.name === 'Link Button')
  const link = element?.actions?.['link'];
  const buttonInner = (
    <div className="listing-button">
      {parse(sanitizeHtml(getElementContent('Link Button', elements, language, languageOptions, isEditor), WHITE_LIST_HTML))}
      <IconArrowsRight size={27} />
    </div>)
  let buttonComponent;
  if (link?.type === 'inner') {
    const innerLink = APP_PATHS.virtual + '/' + slug + '/' + link.link + queryString;
    buttonComponent = <NavLink to={innerLink}>{buttonInner}</NavLink>;
  } else if (link?.type === 'outer') {
    buttonComponent = <a target="_blank" rel="noreferrer" href={link.link}>{buttonInner}</a>
  } else {
    buttonComponent = buttonInner;
  }
  return buttonComponent;
}

function ListingCard(props) {
  const { elements, slug } = props;
  const { languages = ['en', 'fi'], isEditor, setActiveItem } = useContext(SettingContext);
  const languageOptions = languages;
  const lang = useCurrentLanguage(isEditor);
  const queryString = useAccessKey();
  const buttonComponent = generateButton(elements, slug, queryString, lang, languageOptions, isEditor);
  const titleElement = elements.find(e => e.name === "Title")
  const titleDropShadow = titleElement?.properties?.dropShadow
  const titleStyle = { filter: titleDropShadow }
  const dispatch = useDispatch()

  const handleDoubleClick = (name) => {
    if (isEditor) {
      const elem = elements.find(e => e.name === name)
      if (elem) {
        setActiveItem(elem)
        dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: elem.renderType, show: true } })
      }
    }
  }

  return (
    <>
      <div
        className="listing-card"
        style={{ backgroundImage: `url(${getElementContent('Background', elements, lang, languageOptions, isEditor)})` }}
      >
        <div className="listing-header-container">
          <div
            className="listing-logo"
            style={{
              backgroundImage: `url(${getElementContent('Logo', elements, lang, languageOptions, isEditor)})`
            }}
            onDoubleClick={() => handleDoubleClick("Logo")}
          ></div>
          <p className="listing-description" onDoubleClick={() => handleDoubleClick("Description")}>
            {parse(sanitizeHtml(getElementContent('Description', elements, lang, languageOptions, isEditor), WHITE_LIST_HTML))}
          </p>
        </div>
        <div className="listing-bottom-overlay">
          <p className="listing-title" onDoubleClick={() => handleDoubleClick("Title")} style={titleStyle}>{parse(sanitizeHtml(getElementContent('Title', elements, lang, languageOptions, isEditor), WHITE_LIST_HTML))}</p>
          {buttonComponent}
        </div>
      </div>
    </>);
}

export default ListingCard