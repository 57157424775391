import { Localized } from "@fluent/react";
import { Card } from "react-bootstrap";

const StackedSlots = ({ hour, handleViewSlots }) => {
    return (
        hour.content
        &&
        <>
            <Card key={`stacked_${hour.hour}`} className="slot-card shadow m-2 stack stack-hover" style={{ zIndex: "3", left: "0rem" }} onClick={(e) => handleViewSlots(e, hour.hour)}>
                <Card.Body className="stack-card-body">
                    <Localized id='stacked-slots-amount' vars={{ slotAmount: hour.content.length }}>
                        <Card.Title className='timeslot-header'>{'{$slotAmount} timeslots within an hour'}</Card.Title>
                    </Localized>
                    <Localized id='stacked-slots-click-text'>
                        <Card.Subtitle className="mb-2 text-muted">Click to view all</Card.Subtitle>
                    </Localized>
                </Card.Body>
            </Card>
            <Card className="shadow m-2 pb-2 stack" style={{ left: '0.3rem', zIndex: "2" }}>
            </Card>
            <Card className="shadow m-2 pb-2 stack" style={{ left: '0.6rem', zIndex: "1" }}>
            </Card>
        </>
    )
}

export default StackedSlots