/** @format */

import { LivetoLoader } from "components/icons";
import { useDeleteEventSlot } from "../apiCalls";
import moment from "moment";
import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAddEventSlot } from "../apiCalls";
import EventTimeSlot from "./EventTimeSlot";
import SideBar from "./SideBar";
import { calculateHeight } from "../helpers/slotHeightCalculator";

const SingleScheduleView = ({ schedule, close }) => {
	const slug = useSelector(state => state.event.slug);
	const scheduleState = useSelector(state => state.schedule);
	const [sideBarOpen, setSideBarOpen] = useState(false);
	const [slotDetails, setSlotDetails] = useState(null);
	const [save, setSave] = useState(false);
	const [saveDel, setSaveDel] = useState(false);
	const [data, setData] = useState(null);

	const addToFavoritesResponse = useAddEventSlot(save, data, slug);
	const deleteFromFavorites = useDeleteEventSlot(saveDel, data, slug);

	useEffect(() => {
		if (addToFavoritesResponse.status === 201) {
			setSave(false);
		}
		if (deleteFromFavorites.status === 204) {
			setSaveDel(false);
		}
	}, [scheduleState]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleAddToFavorites = (e, isFavorite, slot) => {
		e.stopPropagation();
		try {
			setData(slot);
			if (isFavorite) {
				setSaveDel(true);
			} else {
				setSave(true);
			}
		} catch (error) {
			console.error("Error", error);
		}
	};

	return (
		<div className="single-schedule-view-content">
			<SideBar
				isOpen={sideBarOpen}
				slot={slotDetails}
				handleAddToFavorites={handleAddToFavorites}
				close={close}
			/>
			{schedule ? (
				<>
					<div className="single-schedule-view-title">{schedule.name}</div>
					{schedule.program.timeslots.map((slot, i) => (
						<div
							className="event-timeslot-container"
							key={`schedule_slot_${i}`}
							style={{
								height: `${calculateHeight(
									moment(slot.end_time).diff(moment(slot.start_time), "minutes")
								)}px`,
							}}
						>
							<div className="timeline-slot">
								<div className="timeline-hour">{moment(slot.start_time).format("HH:mm")}</div>
								<div className="empty-div-border-left"></div>
								{slot === schedule.program.timeslots[schedule.program.timeslots.length - 1] && (
									<div className="timeline-hour">{moment(slot.end_time).format("HH:mm")}</div>
								)}
							</div>
							<EventTimeSlot slot={slot} key={`event_slot_${i}`} />
						</div>
					))}
				</>
			) : (
				<Container className="h-100">
					<Row className="align-items-center h-100">
						<div className="col-6 mx-auto">
							<div className="centered-notification">
								<LivetoLoader size="100" color="#C4281A" />
							</div>
						</div>
					</Row>
				</Container>
			)}
		</div>
	);
};

export default SingleScheduleView;
