import { Card } from 'react-bootstrap'
import { IconStar } from "components/icons"
import moment from 'moment'
import { Localized } from '@fluent/react'
import { connect, useSelector } from 'react-redux'
import * as a from 'actiontypes'
import { useRef } from 'react'

const mapStateToProps = state => ({
    calendar: state.schedule.calendar,
    sidebar: state.components.sidebar
})

const mapDispatchToProps = dispatch => ({
    handleSidebar: payload => dispatch({ type: a.HANDLE_SIDEBAR, payload })
})

const Slot = ({ calendar, slot, i, handleAddToFavorites, cardMaxHeight, setPosition, hour, handleSidebar, isExpanded, zIndex, sidebar }) => {
    const activeUser = useSelector(state => state.user)
    const slotCardRef = useRef(null)

    const handleSidebarClick = (e) => {
        e.stopPropagation()
        if (sidebar.isOpen && sidebar.slotRef?.current === slotCardRef.current) {
            handleSidebar({ isOpen: !sidebar.isOpen, slot: null, slotRef: null })
        } else {
            handleSidebar({ isOpen: true, slot, slotRef: slotCardRef })
        }
        
    }

    return (
        <Card ref={slotCardRef} onClick={(e) => handleSidebarClick(e)} key={i} className={`slot-card shadow m-2 pb-2 ${isExpanded ? 'expanded-card' : 'stack stack-hover'}`} style={isExpanded ? {} : {left:`${slot.index * 3 * zIndex}rem`, /* zIndex:`${stackIndex - slot.zIndex}`,  */height:`${slot.duration <= 60 ? 95 : cardMaxHeight*slot.duration}%`, top:`${setPosition(slot, hour, moment)}%` /* top:`${(slot.index - 1)/2}vh` */}}>
            <Card.Body className="slot-card-body">
                <Card.Title className='timeslot-header'>{slot.name.length > 10 ? `${slot.name.substring(0,10)}...` : slot.name}</Card.Title>
                <Card.Subtitle>
                    {
                        slot.performers
                        ?
                            <Localized id='slot-event-type-text'>
                                <span>Event timeslot</span>
                            </Localized>
                            
                        :
                            <>
                                <Localized id='slot-meeting-type-text'>
                                    <span>Meeting:</span>
                                </Localized>
                                {` `}
                            </>
                    }
                    
                    {
                        !activeUser.guestuser
                        ?
                            slot.sent_invites
                            ?
                                slot.sent_invites[0].status === 'pending'
                                ?
                                    <Localized id='slot-invite-status-pending'>
                                        <span className='color-favorite'>
                                            pending
                                        </span>
                                    </Localized>
                                :
                                slot.sent_invites[0].status === 'accepted'
                                ?
                                    <Localized id='slot-invite-status-accepted'>
                                        <span className='accepted'>
                                            accepted
                                        </span>
                                    </Localized>
                                :
                                    ""
                            :
                            slot.isDuplicate
                            ?
                                slot.received_invite.status === 'pending'
                                ?
                                    <Localized id='slot-invite-status-pending'>
                                        <span className='color-favorite'>
                                            pending
                                        </span>
                                    </Localized>
                                :
                                slot.received_invite.status === 'accepted'
                                ?
                                    <Localized id='slot-invite-status-accepted'>
                                        <span className='accepted'>
                                            accepted
                                        </span>
                                    </Localized>
                                :
                                    ""
                            :
                                ""
                        :
                            ""
                    }
                </Card.Subtitle>
                <Card.Text className="slot-time">
                    {moment(slot.start_time).format('HH:mm')} - {moment(slot.end_time).format('HH:mm')}
                </Card.Text>
            
            {
                !activeUser.guestuser
                ?
                    calendar
                        ?
                        slot.performers
                            ?
                            calendar.event_timeslots
                                ?
                                calendar.event_timeslots.map(et => et.id).includes(slot.id)
                                    ?
                                        <div className="constent-justify-right text-right">
                                            <IconStar size="30" className={`cursor-pointer color-favorite icon-clickable star`} onClick={(e) => handleAddToFavorites(e, true, slot, 'schedule-notification-alert')} />
                                        </div>
                                    :
                                        <div className="constent-justify-right text-right">
                                            <IconStar size="30" className="cursor-pointer color-not-favorite icon-clickable star" onClick={(e) => handleAddToFavorites(e, false, slot, 'schedule-notification-alert')} />
                                        </div>
                                :
                                    ""
                            :
                                ""
                        :
                        ""
                :
                    ""
            }
            </Card.Body>
        </Card>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Slot)