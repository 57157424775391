import { useEffect, useState } from "react"
import { getScheduleTimeline } from "../helpers/getWeekHelper"
import ScheduleCard from "./ScheduleCard"

const BackgroundLine = () => {
    return <div className="background-line"></div>
}

const ScheduleListMobile = ({ schedules, handleViewSchedule, activeSchedule }) => {
    const [timeline, setTimeline] = useState(null)
    const [totalHrs, setTotalHrs] = useState(null)
    const [lines, setLines] = useState([])

    useEffect(() => {
        if (schedules) {
            setTimeline(getScheduleTimeline(schedules).dayScale)
            setTotalHrs(getScheduleTimeline(schedules).totalHrs)
            let lineArr = []
            for (let i = 0; i < getScheduleTimeline(schedules).totalHrs; i++) {
                lineArr.push(<BackgroundLine />)
            }

            setLines(lineArr)
        }

    }, [schedules])// eslint-disable-line react-hooks/exhaustive-deps
    return <div className="schedule-list-container" style={{ height: `${totalHrs * 70}px` }}>
        <div>
            <div className="background-lines-container">
                {lines}
            </div>
            {timeline &&
                timeline.map((time, index) =>
                    <>
                        <div className='timeline-slot-schedule-list'>
                            <div className='timeline-hour'>
                                {time.start.format('HH:mm')}
                            </div>
                            <div className="empty-div-border-left">
                            </div>

                        </div>
                        {time === timeline[timeline.length - 1] &&
                            <div className='timeline-hour last-hour'>
                                {time.end.format('HH:mm')}
                            </div>
                        }
                    </>)}
        </div>

        {(activeSchedule && timeline) &&
            <ScheduleCard scheduleStartTime={timeline[0].start} schedule={activeSchedule} handleViewSchedule={handleViewSchedule} />}
    </div>
}

export default ScheduleListMobile