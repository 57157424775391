
export const createIndexes = (timeline, slots, moment) => {

    let indexedSlots = []

    for (let i = 0; i < timeline.length; i++) {
        let arr = []
        let j = 0
        slots.forEach(fs => {
            if ((moment(fs.start_time) >= moment(timeline[i].start) && moment(fs.end_time) <= moment(timeline[i].end)) || (moment(fs.start_time) >= moment(timeline[i].start) /* && fs.duration >= 60  */&& moment(fs.start_time) < moment(timeline[i].end))) {
                fs.index = j
                fs.zIndex = j
                arr.push(fs)
                j++
            }
        })
        if (arr.length > 0) {
            indexedSlots.push({
                content: arr,
                hour: moment(timeline[i].start).format('HH:mm'),
                day: moment(timeline[i].start).format('DD.MM.YYYY'),
                start: timeline[i].start,
                end: timeline[i].end
            })
        }
    }

    let overlappedSlots = []
    
    slots.forEach(s => {
        slots.forEach(sl => {
            if (s.start_time > sl.start_time && s.start_time < sl.end_time && sl.duration > 60 && s !== sl && moment(s.start_time).format('HH') !== moment(sl.start_time).format('HH') && s.index === sl.index) {
                overlappedSlots.push(s)
            }
        })
    })

    indexedSlots.forEach(is => {
        is.content.filter(c => c === overlappedSlots.find(os => os === c)).forEach(f => {
            if (f) {
                f.index++
            }
        })
    })

    let singles = []
    indexedSlots.filter(is => is.content.length === 1).forEach(iSlot => {
        iSlot.content.forEach(c => {
            if (c.duration > 60) {
                singles.push(c)
            }
            
        })
    })

    indexedSlots.forEach(is => {
        if (is.content.length === 1) {
            if (singles.length === 0) {
                is.content[0].index = 0
            } else {
                singles.forEach(slot => {
                    if (!(is.content[0].start_time > slot.start_time && is.content[0].end_time <= slot.end_time)) {
                        is.content[0].index = 0
                    }
                })
            }
            
        }
        
    })


    return indexedSlots
}