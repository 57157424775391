import { useEffect, useState } from "react";
import { getWeekHelper } from "./helpers/getWeekHelper";
import SlotList from "./components/SlotList";
import moment from 'moment'
import { connect, useSelector } from "react-redux";
import { Localized } from "@fluent/react";
import { Container, Row } from "react-bootstrap";
import { LivetoLoader } from "components/icons";

const mapStateToProps = state => ({
  calendar: state.schedule.calendar,
})

const Calendar = ({ calendar={}, calendarLoading, close }) => {
  const { received_invites=[], event_timeslots=[], slots=[] } = calendar
  const scheduleState = useSelector(state => state.schedule)
  const [thisWeek, setThisWeek] = useState([])
  const [activeDate, setActiveDate] = useState("")

  useEffect(() => {
    if (scheduleState && Object.keys(calendar).length > 0 && !calendarLoading) {
      let acceptedInviteSlots = received_invites
        .filter(i => i.status === 'accepted')
      acceptedInviteSlots
        .forEach(received_invite => {
          received_invite.slot.isDuplicate = true
          received_invite.slot.received_invite = received_invite
        })

      acceptedInviteSlots = acceptedInviteSlots.map(s => s.slot)
      const acceptedSentSlots = slots.filter(s => s.sent_invites?.length > 0 ? s.sent_invites[0].status !== 'rejected' && s.sent_invites[0].status !== 'cancelled' : null)
      const allSlots = acceptedInviteSlots.concat(event_timeslots.concat(acceptedSentSlots))

      const thisWeekSlots = getWeekHelper(allSlots).days
      const active = thisWeekSlots.find(slot => slot.active)
      if (active) {
        if (activeDate === "" && thisWeekSlots.length > 0) {
          setActiveDate(active.fullDate)
        } else if (activeDate !== active.fullDate) {
          setActiveDate(active.fullDate)
        }
      }

      setThisWeek(thisWeekSlots)
    }

  }, [scheduleState])// eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDayClick = (e, day) => {
    e.stopPropagation()

    const newWeek = thisWeek.map(d => {
      if (d.active && d !== day) {
        d.active = false
      }
      if (!d.active && d === day) {
        d.active = true
      }
      return d
    })
    setThisWeek(newWeek)
    setActiveDate(day.fullDate)
  }

  return (
    <div className="calendar-content">
      <div className="virtual-event-schedule-dates">
        {thisWeek.map((day, i) =>
          <div key={`calendar_date_${i}`} className={"virtual-event-schedule-date" + (day.fullDate === activeDate ? ' selected' : '')} onClick={(e) => handleChangeDayClick(e, day)}>{moment(day.fullDate).format('DD.MM.')}</div>
        )}
      </div>
      {
        !calendarLoading
        ?
          thisWeek.length > 0
            ?
            <SlotList thisWeek={thisWeek} activeDate={activeDate} dates={thisWeek.map(d => d.fullDate)} close={close} />
            :
            <div className="centered-notification">
              <Localized id='your-calendar-is-empty'>
                Your calendar is empty
              </Localized>
            </div>
        :
        <Container className="h-100">
          <Row className="align-items-center h-100">
            <div className="col-6 mx-auto">
              <div className="centered-notification">
                <LivetoLoader size="100" color="#C4281A" />
              </div>
            </div>
          </Row>
        </Container>
      }
    </div>
  )
}

export default connect(mapStateToProps)(Calendar)