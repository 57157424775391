import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCookie } from './cookies';

const Tr = (props) => {
    const { translations, component, target } = props;
    const language = useCurrentLanguage();
    return translations[component][target][language];
}

export const RawTR = (props) => {
    const { translations, component, target } = props;
    const language = useCurrentLanguage();
    return props.render(translations[component][target][language]);
}

export const createTranslations = (component, translations) => {
    return (target) => <Tr component={component} translations={translations} target={target} />
}

// Use current userlanguage if it is allowed in the event, 
// if not take the first language that is allowed.
// If the user isn't registered but has language cookie set from our
// other websites, use that if it is allowed

export const useCurrentLanguage = (isEditor=false) => {
    const userLanguage = useSelector(state => state.user?.language);
    const eventLanguages = useSelector(state => state.event?.languages);
    const editorLanguage = useSelector(state => state.editorLanguage);
    const browserLang = (navigator?.language || navigator?.userLanguage || navigator.languages[0]).split('-')[0]

    const [language, setLanguage] = useState(browserLang);
    useEffect(() => {
        const lang = (isEditor ? editorLanguage : null) ||
            (eventLanguages?.includes(userLanguage) ? userLanguage : eventLanguages?.[0])
            || (eventLanguages?.includes(getCookie('liveto-user-language')) ? getCookie('liveto-user-language') : null)
            || browserLang
        setLanguage(lang === 'sv' ? 'se' : lang);
    }, [userLanguage, eventLanguages, editorLanguage, browserLang, isEditor])

    return language
}

// Gets all the possible languages in the event with user chosen language as the priority (first index)
// Output changes if user preference or allowed languages change

export const usePossibleLanguages = () => {
    const userLanguage = useSelector(state => state.user?.language);
    const eventLanguages = useSelector(state => state.event?.languages);

    const [possibleLanguages, setPossibleLanguages] = useState([])

    useEffect(() => {
        const uniqueAllowedLanguages = eventLanguages?.includes(userLanguage)
            ? [...new Set([userLanguage, ...eventLanguages])]
            : eventLanguages;
        setPossibleLanguages(uniqueAllowedLanguages)
    }, [userLanguage, eventLanguages])

    return possibleLanguages
}

// Cases:
// If no element or no content in element, return empty string
// If content in users language or user in editormode - return content in given language
// If everything else fails, check if there is content in any other allowed language and show that
// Out of options - return empty string;

export const getDefaultOrAnyContent = (element, language, languageOptions, editorMode) => {
    if (!element || !element?.content) return '';
    if (element.content?.[language]?.length > 0 || editorMode) return element.content[language]
    for (let lang of languageOptions) {
        if (element.content?.[lang]?.length > 0 && languageOptions.includes(lang)) {
            return element.content[lang];
        }
    }
    return '';
}