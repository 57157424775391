import parse from 'html-react-parser';
import { useContext } from 'react';

import sanitizeHtml from "sanitize-html"
import {  WHITE_LIST_HTML } from "constants.js";
import ElementWrapper from "containers/VirtualEvent/wrappers/elementWrapper";
import { SettingContext } from 'containers/VirtualEvent/contexts';
import { getDefaultOrAnyContent, useCurrentLanguage } from 'util/localization';

export const BUTTON_ELEMENT_DEFAULT_PROPERTIES = {
    padding: '0 0 0 0',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "no-repeat",
        "position": "center"
    },
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    alignItems: 'center',
    display: 'flex',
    width: 'fit-content',
    height: 'auto',
    boxShadow: 'none',
}

function ButtonElement(props) {
    const { languages = ['en', 'fi'], isEditor } = useContext(SettingContext);
    const language = useCurrentLanguage(isEditor);
    const languageOptions = languages;
    
    const content = getDefaultOrAnyContent(props.elem, language, languageOptions);

    const text = parse(sanitizeHtml(content, WHITE_LIST_HTML));

    const wrapperProps = {
        elem: props.elem,
        defaultStyle: BUTTON_ELEMENT_DEFAULT_PROPERTIES,
        className: `button-element ${props.elem.i}`
    }
    return <ElementWrapper {...wrapperProps}>
        {text}
    </ElementWrapper>
}

export default ButtonElement