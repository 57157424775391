export const setPosition = (slot, hour, moment) => {
    const hourSize = 100 / 60

    if (moment(slot.start_time) > moment(hour.start) && moment(slot.end_time) > moment(hour.end)) {
        const startMin = parseInt(moment(slot.start_time).format('mm'))
        const startPos = hourSize * startMin
        
        return startPos
    } else {
        return null
    }
}