import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { getScheduleTimeline } from "../helpers/getWeekHelper"
import ScheduleCard from "./ScheduleCard"

const BackgroundLine = () => {
    return <div className="background-line"></div>
}

const ScheduleList = ({ schedules, handleViewSchedule, forwardedRef, detectScroll }) => {
    const [timeline, setTimeline] = useState(null)
    const [totalHrs, setTotalHrs] = useState(null)
    const [lines, setLines] = useState([])

    useEffect(() => {
        if (schedules) {
            setTimeline(getScheduleTimeline(schedules).dayScale)
            setTotalHrs(getScheduleTimeline(schedules).totalHrs)
            let lineArr = []
            for (let i = 0; i < getScheduleTimeline(schedules).totalHrs; i++) {
                lineArr.push(<BackgroundLine key={`background_line_${i}`} />)
            }
            setLines(lineArr)
        }

    }, [schedules])// eslint-disable-line react-hooks/exhaustive-deps

    return <div className="schedule-list-container" style={{ height: `${totalHrs * 70}px` }}>
        <div>
            <div className="background-lines-container">
                {lines}
            </div>
            {timeline &&
                timeline.map((time, index) =>
                    <div key={`timeline_${index}`}>
                        <div className='timeline-slot-schedule-list'>
                            <div className='timeline-hour'>
                                {time.start.format('HH:mm')}
                            </div>
                            <div className="empty-div-border-left">
                            </div>
                        </div>
                        {time === timeline[timeline.length - 1] &&
                            <div className='timeline-hour last-hour'>
                                {time.end.format('HH:mm')}
                            </div>}
                    </div>)}
        </div>

        {(schedules && timeline) &&
            <div className="schedules-container" ref={forwardedRef} onScroll={(e) => detectScroll(e, forwardedRef)}>
                {schedules.map(schedule =>
                    <ScheduleCard key={schedule.id} scheduleStartTime={timeline[0].start} schedule={schedule} handleViewSchedule={handleViewSchedule} />
                )}
                <div className="dummy-scroll-to-right" />
            </div>}
    </div>
}

export default ScheduleList