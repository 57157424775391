import { Row, Col } from "react-bootstrap"
import { useEffect, useState } from "react"
import moment from 'moment'
import { getTimeline } from "../helpers/getWeekHelper"
import { createIndexes } from "../helpers/indexHandler"
import { setPosition } from "../helpers/positionDefiner"
import StackedSlots from "./StackedSlots"
import ExpandedSlots from "./ExpandedSlots"
import { IconCompress } from "components/icons"
import { useAddEventSlot, useDeleteEventSlot } from "containers/VirtualEvent/schedule/apiCalls"
import { useSelector } from "react-redux"
import Slot from "./Slot"
import SideBar from "./SideBar"
import { useCancelInvite } from "../apiCalls"

const SlotList = ({ thisWeek, activeDate, dates, close }) => {
    const slug = useSelector(state => state.event.slug)
    const scheduleState = useSelector(state => state.schedule)
    const [todaySlots, setTodaySlots] = useState([])
    const [filteredSlots, setFilteredSlots] = useState([])
    const [indexedSlots, setIndexedSlots] = useState([])
    const [timeline, setTimeline] = useState([])
    const [cardMaxHeight] = useState(100 / 60)
    const [isHourExpanded, setIsHourExpanded] = useState("")
    const [save, setSave] = useState(false)
    const [saveDel, setSaveDel] = useState(false)
    const [data, setData] = useState(null)
    const [cancelData, setCancelData] = useState(null)
    const [saveCancel, setSaveCancel] = useState(false)
    const [sideBarOpen, setSideBarOpen] = useState(false)
    const [slotDetails, setSlotDetails] = useState(null)

    const addToFavoritesResponse = useAddEventSlot(save, data, slug)
    const deleteFromFavorites = useDeleteEventSlot(saveDel, data, slug)
    const cancelInvite = useCancelInvite(saveCancel, cancelData, slug)

    useEffect(() => {

        // Filtering out event slots and empty sent_invites arrays
        let thisDaySlots = []
        if (activeDate !== "" && dates.map(d => moment(d).format('YYYY-MM-DD')).includes(activeDate)) {

            thisDaySlots = thisWeek.length > 0 ? thisWeek.filter(d => d.fullDate === activeDate)[0].slots : []
        } else {
            thisDaySlots = thisWeek.length > 0 ? thisWeek.filter(d => d.active)[0].slots : []
        }
        setTodaySlots(thisDaySlots)
        setFilteredSlots(thisDaySlots)

        setTimeline(getTimeline(thisDaySlots))
    }, [thisWeek, activeDate])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsHourExpanded("")
    }, [activeDate])

    useEffect(() => {
        setIndexedSlots(createIndexes(timeline, todaySlots, moment))
    }, [filteredSlots, timeline])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (addToFavoritesResponse.status === 201) {
          setSave(false)
      }
      if (deleteFromFavorites.status === 204) {
        setSaveDel(false)
      }
      if (cancelInvite.status === 204 || cancelInvite.status === 200) {
        setSaveCancel(false)
      }
  }, [scheduleState])// eslint-disable-line react-hooks/exhaustive-deps

    const handleAddToFavorites = (e, isFavorite, slot) => {
        e.stopPropagation()
        try {
            setData(slot)
            if (isFavorite) {
                setSaveDel(true)
            } else {
                setSave(true)
            }
        } catch (error) {
            console.error("Error", error)
        }
    }

    const handleCancelInvite = (e, slot) => {
        e.stopPropagation()
        setSideBarOpen(false)
        setIsHourExpanded("")
        setCancelData(slot)
        setSaveCancel(true)
    }

    const handleViewSlots = (e, hour) => {
        e.stopPropagation()
        setIsHourExpanded(hour)
    }

    const handleStackExpandedSlots = (e) => {
        e.stopPropagation()
        setIsHourExpanded("")
    }

    const checkIndex = (indexedSlots, index) => {
        if (indexedSlots[index - 1]) {
            if (indexedSlots[index - 1].content.length > 1) {
                return 0
            } else {
                return 1
            }
        } else {
            return 1
        }
    }

    return (
        <div className="calendar-slotlist-content">
            <SideBar isOpen={sideBarOpen} slot={slotDetails} handleCancelInvite={handleCancelInvite} handleAddToFavorites={handleAddToFavorites} close={close} />
            {todaySlots.length > 0 &&
                timeline.map((time, index) =>
                    <Row className="mx-25 calendar-timeline-row" key={`hour_${index}`}>
                        <Col sm={2} className={`${time.start.format('HH:mm') === isHourExpanded && indexedSlots.find(iSlot => iSlot.hour === isHourExpanded)?.content?.length !== 1 ? 'expanded' : "timeline-row"}`} >
                            <div className='timeline-slot-list-container'>
                                <div className='timeline-hour'>
                                    {time.start.format('HH:mm')}
                                </div>
                                <div className="empty-div-border-left"></div>
                            </div>
                            {time === timeline[timeline.length - 1] &&
                                <div className='timeline-hour'>{moment(time.end).format('HH:mm')}</div>}
                        </Col>
                        <Col sm={10} className='hour-row'>
                            <Row className={`${time.start.format('HH:mm') === isHourExpanded && indexedSlots.find(iSlot => iSlot.hour === isHourExpanded).content.length !== 1 ? 'inner-expanded' : ""}`}>
                                {
                                    indexedSlots.map((hour, index) =>
                                        hour.hour === moment(time.start).format('HH:mm') && hour.day === moment(time.start).format('DD.MM.YYYY')
                                            ?
                                            hour.content.length > 1
                                                ?
                                                hour.hour !== isHourExpanded
                                                    ?
                                                    <StackedSlots key={`stacked_${index}`} hour={hour} handleViewSlots={handleViewSlots} />
                                                    :
                                                    <>
                                                        <ExpandedSlots
                                                            key={`expanded_${index}`}
                                                            hour={hour}
                                                            handleAddToFavorites={handleAddToFavorites}
                                                            setPosition={setPosition}
                                                            index={index}
                                                        />
                                                        <IconCompress size="30" className="compress-stack icon-clickable cursor-pointer color-liveto mt-2 mr-2 ml-1" onClick={(e) => handleStackExpandedSlots(e)} />
                                                    </>
                                                :
                                                hour.content.map((slot, i) =>
                                                    <Slot
                                                        key={`slot_${i}`}
                                                        i={i}
                                                        slot={slot}
                                                        hour={hour}
                                                        handleAddToFavorites={handleAddToFavorites}
                                                        setPosition={setPosition}
                                                        cardMaxHeight={cardMaxHeight}
                                                        zIndex={checkIndex(indexedSlots, index)}
                                                    />
                                                )
                                            :
                                            ""
                                    )
                                }
                            </Row>
                        </Col>

                    </Row>
                )}
        </div>
    )
}
export default SlotList