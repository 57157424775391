
import parse from 'html-react-parser';
import sanitizeHtml from "sanitize-html"
import { WHITE_LIST_HTML } from "constants.js";
import './elements.scss';
import { useSelector } from 'react-redux';
export const SLIDE_STYLES = {
    padding: '0 0 0 0',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    filter: 'none',
    borderRadius: '0px',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
    overlay: null,
}

function parseBackgroundStyles(properties) {
    const {
        padding = SLIDE_STYLES['padding'],
        background = SLIDE_STYLES['background'],
        borderParams = SLIDE_STYLES['border'],
        justifyContent = SLIDE_STYLES['justifyContent'],
        alignItems = SLIDE_STYLES['alignItems']
    } = properties;

    const {
        size,
        color,
        image,
        repeat,
        position
    } = background;

    const {
        border,
        borderRadius
    } = borderParams;

    return {
        padding: padding,
        backgroundSize: size,
        backgroundColor: color,
        backgroundImage: image,
        backgroundRepeat: repeat,
        backgroundPosition: position,
        border: border,
        borderRadius: borderRadius,
        display: 'flex',
        justifyContent: justifyContent,
        alignItems: alignItems,
        position: 'relative'
    }
}

function parseOverlayStyles(properties) {
    return {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: properties['overlay'] ? properties['overlay'] : 'rgba(0,0,0,0)',
        pointerEvents: 'none'
    };
}

const parseContent = (content) => {
    return parse(sanitizeHtml(content, WHITE_LIST_HTML)) === "undefined" ? "" : parse(sanitizeHtml(content, WHITE_LIST_HTML))
}

export default function Slide({ elem }) {
    const editorLang = useSelector(state => state.editorLanguage)
    const userLang = useSelector(state => state.user.language)
    const language = editorLang || userLang
    const { properties, content, i } = elem;
    const text = typeof content === 'object' ? (parseContent(content[language])) : parseContent(content);
    return <div
        className={`carousel-item-wrapper element-hilight-wrapper ${i}`}
        style={parseBackgroundStyles(properties)}
        >
        <div style={parseOverlayStyles(properties)}></div>
        <span style={{ zIndex: 1 }}>
            {text}
        </span>
    </div>
}

