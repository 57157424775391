import { Row } from "react-bootstrap"
import Slot from "./Slot"

const ExpandedSlots = ({ hour, handleAddToFavorites, handleDeleteSlot, index }) => {
    
    return (
        <Row className="expanded-card-row" key={index}>
            {hour.content.map((slot, i) => 
                <Slot
                    key={i}
                    i={i}
                    slot={slot}
                    hour={hour}
                    handleAddToFavorites={handleAddToFavorites}
                    handleDeleteSlot={handleDeleteSlot}
                    isExpanded={true}
                />
            )}
        </Row>
    )
}

export default ExpandedSlots