import { useEffect, useState } from 'react';
import './css/calendar.scss';
import { Modal } from "react-bootstrap"
import Calendar from './calendar';
import { Localized } from '@fluent/react';
import { connect } from 'react-redux';
import { useFetchCalendarData } from './apiCalls';
import { CalendarNav } from './components/ScheduleNav';

const mapStateToProps = state => ({
    calendar: state.schedule.calendar,
    close_modals: state.close_modals
})

const mapDispatchToProps = dispatch => ({
    closeModals: () => dispatch({ type: 'HANDLE_MODALS', payload: true })
})

const CalendarModal = (props) => {
    const { showCalendar, setShowCalendar, slug, calendar, close_modals } = props;
    const [getCalendar, setGetCalendar] = useState(true)
    const { calendarLoading } = useFetchCalendarData(slug, getCalendar)
    const [scheduleInfoText] = useState(
        <div>
            <Localized id='calendar-info-text'><div>This is your personal calendar. It includes your favorite programs and scheduled meetings.</div></Localized>
            <Localized id='schedule-info-text'><div>The times in this program are displayed according to your own time zone.</div></Localized>
        </div>
    )
    
    useEffect(() => {
        if (!showCalendar) {
            setGetCalendar(false)
        } else {
            setGetCalendar(true)
        }
    }, [showCalendar])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (close_modals) {
            close()
        }
    }, [close_modals])// eslint-disable-line react-hooks/exhaustive-deps

    function close(){
        setShowCalendar(false);
    }

    const handleCloseSchedule = () => {
        setShowCalendar(false);
    }

    return (
        <Modal className='p-0' animation={false} centered show={showCalendar} onHide={close} backdropClassName="schedule-modal-backdrop" dialogClassName="schedule-modal-dialog" contentClassName='virtual-event-schedule'>
            <CalendarNav scheduleInfoText={scheduleInfoText} handleCloseSchedule={handleCloseSchedule} />
            

            <div className="modal-inner-content overflow-auto" style={{flex:"1 1 auto"}} /* onClick={close} */>
            {
                calendar
                &&
                <Calendar eventSlug={slug} calendarLoading={calendarLoading} showCalendar={showCalendar} close={close} />
            }
            </div>
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal)
