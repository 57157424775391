import parse from 'html-react-parser';
import { useContext } from 'react';
import sanitizeHtml from "sanitize-html"
import { WHITE_LIST_HTML } from "constants.js";
import ElementWrapper from 'containers/VirtualEvent/wrappers/elementWrapper';
import { SettingContext } from 'containers/VirtualEvent/contexts';
import { getDefaultOrAnyContent, useCurrentLanguage } from 'util/localization';

export const TEXT_ELEMENT_STYLE = {
    padding: '0 0 0 0',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    background: {
        "size": "contain",
        "color": "rgba(0,0,0,0)",
        "image": "",
        "repeat": "repeat",
        "position": "center"
    },
    border: {
        border: '0px solid rgba(0,0,0,0)',
        borderRadius: '0px',
    },
    filter: 'none',
    width: 'fit-content',
    height: 'fit-content',
}

function Text(props) {
    const { languages, isEditor } = useContext(SettingContext);
    const language = useCurrentLanguage(isEditor);
    const content = getDefaultOrAnyContent(props.elem, language, languages, isEditor);
    
    const text = parse(sanitizeHtml(content, WHITE_LIST_HTML));

    const wrapperProps = {
        elem: props.elem,
        defaultStyle: TEXT_ELEMENT_STYLE,
        className: `text-element-wrapper ${props.elem.i}`
    }
    return <ElementWrapper {...wrapperProps}>
        {text}
    </ElementWrapper>
}

export default Text;