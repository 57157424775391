import { useEffect, useState, useRef } from "react"
import { Localized } from "@fluent/react"
import { LivetoLoader } from "components/icons"
import ScheduleList from "./components/ScheduleList"
import { connect } from "react-redux"
import ScheduleListMobile from "./components/ScheduleListMobile"
import { IconChevronRight } from "components/icons"
import { IconChevronLeft } from "components/icons"

const mapStateToProps = state => ({
  schedule: state.schedule.schedule,
  dates: state.schedule.schedule.dates,
})

const Program = (props) => {
  const {
    selectedDate,
    scheduleLoading,
    schedule,
    handleViewSchedule,
    displayMode,
    activeSchedules,
    activeSchedule,
  } = props

  const [activeProgramSchedule, setActiveProgramSchedule] = useState(null)
  const [activeDaySchedules, setActiveDaySchedules] = useState(null)
  const [rightArrow, setRightArrow] = useState(true)
  const [leftArrow, setLeftArrow] = useState(false)

  const myRef = useRef(null)

  useEffect(() => {
    if (activeSchedules) {
      setActiveDaySchedules(activeSchedules)
    }
  }, [activeSchedules])

  useEffect(() => {
    if (activeSchedule) {
      setActiveProgramSchedule(activeSchedule)
    }
  }, [activeSchedule])

  const executeScroll = (value) => myRef.current.scrollLeft += value

  const detectScroll = (e, forwardedRef) => {
    const { scrollLeft, clientWidth, scrollWidth } = forwardedRef.current
    const width = scrollWidth - clientWidth
    if (scrollLeft === 0) {
      setLeftArrow(false)
    } else if (scrollLeft === width) {
      setRightArrow(false)
    } else {
      setRightArrow(true)
      setLeftArrow(true)
    }
  }

  const handleChangeScheduleClick = (isRight) => {
    let newIndex
    if (isRight) {
      newIndex = activeDaySchedules.indexOf(activeProgramSchedule) + 1
    } else {
      newIndex = activeDaySchedules.indexOf(activeProgramSchedule) - 1
    }
    let newActiveSchedule = activeDaySchedules[newIndex]
    setActiveProgramSchedule(newActiveSchedule)
  }

  const arrowButtonProps = () => {
    const isMobile = displayMode === 'mobile' && activeProgramSchedule && activeDaySchedules
    return {
      handleLeftArrowClick: isMobile ? () => handleChangeScheduleClick(false) : () => executeScroll(-200),
      handleRightArrowClick: isMobile ? () => handleChangeScheduleClick(true) : () => executeScroll(200),
      isLeftHidden: isMobile ? activeProgramSchedule.id === activeDaySchedules[0].id : leftArrow,
      isRightHidden: isMobile ? activeProgramSchedule.id === activeDaySchedules[activeDaySchedules.length - 1].id : rightArrow
    }
  }

  if (scheduleLoading) return <div className="schedule-program-content">
    <div className="centered-notification">
      <LivetoLoader size="100" color="#C4281A" />
    </div>
  </div>

  if (!schedule.schedules) return <div className="schedule-program-content">
    <div className="centered-notification">
      <Localized id='program-is-empty'>
        This event has no scheduled program
      </Localized>
    </div>
  </div>

  return (
    <div className="schedule-program-content">
      <ArrowButtons {...arrowButtonProps()} />
      {displayMode === "mobile"
        ?
        activeProgramSchedule && selectedDate && <ScheduleListMobile schedules={activeDaySchedules} activeDay={selectedDate} handleViewSchedule={handleViewSchedule} activeSchedule={activeProgramSchedule} />
        :
        <ScheduleList schedules={activeDaySchedules} activeDay={selectedDate} handleViewSchedule={handleViewSchedule} forwardedRef={myRef} detectScroll={detectScroll} />}
    </div>
  )
}

export default connect(mapStateToProps)(Program)

const ArrowButton = (props) => {
  const { isHidden, handleClick, orientation } = props
  return <div className={`schedule-scroll-${orientation}-button ${isHidden && 'schedule-button-hidden'}`} onClick={handleClick}>
    <div className="schedule-scroll-inner-button">
      {orientation === 'forward' && <IconChevronRight />}
      {orientation === 'back' && <IconChevronLeft />}
    </div>
  </div>
}

const ArrowButtons = (props) => {
  const { handleLeftArrowClick, handleRightArrowClick, isLeftHidden, isRightHidden } = props
  return <>
    <ArrowButton
      handleClick={handleRightArrowClick}
      isHidden={isRightHidden}
      orientation="forward"
    />
    <ArrowButton
      handleClick={handleLeftArrowClick}
      isHidden={isLeftHidden}
      orientation="back"
    />
  </>
}