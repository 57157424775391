import { IconImage, IconTimes } from "components/icons";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { patchImage, usePostImage } from "../apiCalls";
import { calcSizeInMB, checkFileTooBig, checkForSpecialChars, compareImageDimensions } from "./helpers";
import { useUploadImages } from "./hooks";
import { uniqueId } from 'lodash'
import Select from 'react-select'
import { Modal } from '@mantine/core';
import { TooltipInfoButton } from "containers/VirtualEvent/schedule/components/ScheduleNav";
import { GenericTooltip } from "components/ToolTip/Tooltip";

export const ImageElement = (props) => {
  const { handleSelect, selected, imageObject } = props;
  const { image, id } = imageObject
  const [imageStyle, setImageStyle] = useState({})
  return <div onClick={e => handleSelect(imageObject)} className={"list-image-container" + (selected?.id === id ? ' selected' : '')}>
    <img onLoad={(e) => compareImageDimensions(e, setImageStyle)} style={imageStyle} src={image} className="list-image" />
  </div>
}

export const FolderElement = (props) => {
  const { handleSelect, folder, children, setFolderList, handleHistory } = props;
  const { nodes = [], path } = folder

  const handleSelectFolder = () => {
    handleHistory()
    handleSelect(path)
    setFolderList(nodes)
  }
  return <div onClick={handleSelectFolder} className={"list-image-container folder"}>
    {children}
  </div>
}

export const NavigationButtons = (props) => {
  const { path, handleMove } = props

  const arr = path.split('/')
  const createPath = (idx) => {
    const newPath = arr.filter((a, i) => i <= idx).join('/')
    return newPath
  }
  const spans = arr.map((span, i) => {
    createPath(i)
    return <>/<span key={uniqueId()} className="path-button" onClick={() => handleMove(i + 1, createPath(i))}>{span}</span></>
  })
  return <div className="hierarchy-path">{spans}</div>
}

export const ImageUploadContainer = (props) => {
  const {
    setShowGallery,
    slug,
    organizer,
    room_slug,
    setFetchImages,
    setFetchFolders,
    userType,
    activeFolder,
    resetState
  } = props

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
  }); // Dropzone hook

  const [uploadImage, setUploadImage] = useState([]);
  useUploadImages(acceptedFiles, setUploadImage) // Listen for dropzone changes

  const [postImage, setPostImage] = useState(false); // Upload new image
  const [folder, setFolder] = useState(activeFolder)

  const [showMessage, uploadMessage, uploadLoading] = usePostImage(
    slug,
    organizer,
    room_slug,
    uploadImage,
    setFetchImages,
    setFetchFolders,
    postImage,
    setPostImage,
    setUploadImage,
    userType,
    folder,
    resetState
  ); // Post API hook

  useEffect(() => {
    setFolder(activeFolder)
  }, [activeFolder])

  function buttonDisabled(images) {
    if (images.length === 0) {
      return true;
    }
    if (images.length > 0) {
      if (calcSizeInMB(images[0].size) > 5) {
        return false
      }
    }
    if (!checkForSpecialChars(folder) && folder) return true
    return false;
  }

  return <div className="image-upload-container">
    <div className="upload-heading-container">
      <h5>Upload images</h5>
      <button className="close-button" onClick={e => setShowGallery(false)}>
        <IconTimes />
      </button>
    </div>
    <div {...getRootProps({ className: 'dropzone', maxSize: 1000, maxFiles: 1 })}>
      <input {...getInputProps()} />
      <div className="dropzone-inner">
        <IconImage size="80" style={{ color: '#555' }} />
        <p className="dropzone-description">Drag 'n' drop some image here, or click to select image from your computer</p>
        <p className="dropzone-filesize">Max file size 5mb</p>
      </div>
    </div>

    <div className="image-preview">
      {uploadImage.length > 0 &&
        <div className="image-container">
          <div className="folder-input-group">
            <label htmlFor="">Folder</label>
            <input type="text" className="save-folder" value={`/${folder}`} onChange={(e) => setFolder(e.target.value.substring(1))} />
          </div>

          <img alt="current" src={URL.createObjectURL(uploadImage[0])} className="image" />
          <p className="image-size">{calcSizeInMB(uploadImage[0].size) + 'MB'} {checkFileTooBig(uploadImage[0].size) ? <span style={{ color: 'red', margin: '0px 5px' }}>File too large!</span> : ''}</p>
        </div>}
    </div>
    <div className="image-upload-footer">
      <div>{showMessage ? uploadMessage : uploadImage.length > 0 ? uploadImage.length + ' kuva valittu' : ''}</div>
      <button className="upload-button" onClick={e => setPostImage(true)} disabled={buttonDisabled(uploadImage)}>{uploadLoading ? 'Tallenetaan...' : 'Upload'}</button>
    </div>
  </div>
}

export const ImageSelectContainer = (props) => {
  const {
    selectedImage,
    setImageToUse,
    activeFolder,
    folders,
    setFetchFolders,
    setFetchImages,
    setSelectedImage,
    resetState
  } = props
  const { image, id, folder } = selectedImage
  const [newFolder, setNewFolder] = useState({ label: folder, value: folder })
  const [createFolder, setCreateFolder] = useState("")
  const [showConfirm, setShowConfirm] = useState(false);
  const [cTitle, setCTitle] = useState(false);
  const [folderAction, setFolderAction] = useState({ chooseAction: false, action: 'move' });
  const options = folders.map(folder => { return { label: folder.folder, value: folder.folder } })
  const [errMsg, setErrMsg] = useState(null)

  useEffect(() => {
    setNewFolder({ label: activeFolder, value: activeFolder })
  }, [activeFolder])

  useEffect(() => {
    if (folder !== newFolder?.value && (createFolder && createFolder !== folder)) {
      setCTitle("Which action would you like to perform?")
      setFolderAction({ chooseAction: true, action: 'move' })
    } else if (createFolder && createFolder !== folder) {

      setFolderAction({ chooseAction: false, action: 'create' })
      setCTitle("Confirm folder")
    } else {
      setFolderAction({ chooseAction: false, action: 'move' })
      setCTitle("Confirm folder")
    }
    if (!checkForSpecialChars(createFolder) && createFolder) setErrMsg("No special characters allowed")
    else setErrMsg(null)
  }, [newFolder?.value, createFolder, id])

  const handleSave = async () => {
    const folderToUpdate = folderAction.action === "move" ? newFolder.value : createFolder
    const [ok, error, res, status] = await patchImage(id, folderToUpdate, setSelectedImage)
    if (ok) {
      resetState()
      setFetchImages(true)
      setFetchFolders(true)
      setSelectedImage(null)
    }
    if (error) setErrMsg(error)
    if (status !== 200) setErrMsg(res.folder)
    setShowConfirm(false)
  }

  const handleChange = (val) => {
    if (!val) {
      setNewFolder({ value: "", label: "" })
      return
    }
    setNewFolder(val)
  }

  const disabled = () => {
    return !createFolder && (folder === newFolder?.value) || (createFolder.trim() === folder.trim() && newFolder.value === folder) || (!checkForSpecialChars(createFolder) && createFolder)
  }

  return <div className="image-listing-select-container">
    <div className="preview-image-container">
      <img alt="preview" src={image} className="preview-image" />
    </div>
    <div className="image-actions">
      <div className="folder-container">

        <div className="folder-group">
          <div className="folder-row">
            <div className="label-row">
              Folder
            </div>
            <div className="select-group">
              {`home/${folder}`}
            </div>
          </div>
        </div>
        <div className="folder-group">
          <div className="folder-row">
            <div className="label-row">
              Move to folder
              <GenericTooltip text="Select existing folder for this image" orientation="bottom" hide="100">
                <div><TooltipInfoButton /></div>
              </GenericTooltip>
            </div>
            <div className="select-group">
              home/<Select
                value={newFolder}
                options={options}
                isMulti={false}
                className="folder-selector"
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={value => handleChange(value)}
                isClearable={true}
              />
            </div>
          </div>
        </div>
        <div className="folder-group">
          <div className="folder-row">
            <div className="label-row">
              Create new folder
              <GenericTooltip text="Create new folder for this image, create subfolders by adding '/' (cats/house_cats)" orientation="bottom" hide="100">
                <div><TooltipInfoButton /></div>
              </GenericTooltip>
            </div>
            <div className="select-group">
              home/<input type="text" className="folder-selector" value={createFolder} onChange={(e) => setCreateFolder(e.target.value)} />
            </div>
          </div>
          {errMsg && <div className="error-message">{errMsg}</div>}
        </div>
      </div>
      <div className="button-container">
        <button className="select" onClick={() => setSelectedImage(false)}>Close</button>
        <button className="select" disabled={disabled()} onClick={() => setShowConfirm(true)}>Save</button>
        <button className="select" onClick={e => setImageToUse()}>Use this image</button>
      </div>
    </div>
    {showConfirm && <ConfirmationModal title={cTitle} showConfirm={showConfirm} setShowConfirm={setShowConfirm}>
      {folderAction.chooseAction === true ?
        <ConfirmationFolderType
          moveToFolderName={newFolder.value}
          createNewFolderName={createFolder}
          value={folderAction.action}
          handleChangeAction={(val) => setFolderAction({ chooseAction: true, action: val })}
        /> :
        <ConfirmationSave
          moveToFolderName={newFolder.value}
          createNewFolderName={createFolder}
          action={folderAction.action}
        />}
      <div className="buttons">
        <button className="generic-liveto decline" onClick={() => setShowConfirm(false)}>
          Cancel
        </button>
        <button className="generic-liveto accept" onClick={handleSave}>
          Submit
        </button>
      </div>
    </ConfirmationModal>}
  </div>
}

export const ConfirmationModal = (props) => {
  const { showConfirm, setShowConfirm, children, title = "" } = props
  return (
    <Modal
      opened={showConfirm}
      onClose={() => setShowConfirm(false)}
      title={<b>{title}</b>}
      className="confirm-modal"
      zIndex={2000}
    >
      <div className="modal-content">
        {children}
      </div>
    </Modal>
  );
}

const ConfirmationFolderType = (props) => {
  const { moveToFolderName, createNewFolderName, value = "move", handleChangeAction } = props
  return <div className="confirmation-content">
    <div className="type-group">
      <div className="name">
        Move this image to folder <b>home/{moveToFolderName}</b>
      </div>
      <input type="checkbox" value="move" checked={value === "move"} onChange={(e) => handleChangeAction(e.target.value)} />
    </div>
    <div className="type-group">
      <div className="name">
        Create folder <b>home/{createNewFolderName}</b> for this image
      </div>
      <input type="checkbox" value="create" checked={value === "create"} onChange={(e) => handleChangeAction(e.target.value)} />
    </div>
  </div>
}

const ConfirmationSave = (props) => {
  const { action = "move", moveToFolderName, createNewFolderName } = props
  return <div className="confirmation-content">
    {action === "move" && <div className="name">
      Move this image to folder <b>home/{moveToFolderName}</b>?
    </div>}
    {action === "create" && <div className="name">
      Create folder <b>home/{createNewFolderName}</b> for this image?
    </div>}
  </div>
}