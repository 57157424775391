import { useEffect, useRef, useState } from 'react';
import './styles/editor.scss';
import { Responsive } from 'react-grid-layout';
import cloneDeep from 'lodash/cloneDeep';
import 'react-grid-layout/css/styles.css';
import { LAYOUT_COLUMNS } from '../constants';
import { useResizeDetector } from 'react-resize-detector';
import { Helmet } from 'react-helmet';
import { HOST } from 'constants.js';
import ComponentRenderer from '../componentLibrary/component';
import { useDispatch } from 'react-redux';

const KEYS_TO_PARSE = ['x', 'y', 'w', 'h']
const ResponsiveGridLayout = Responsive;

function parseComponentGridData(cmp, isEditor) {
    const data = { static: !isEditor };
    // Data returned is matching with react-grid-layout components data-grid:
    for (let key in cmp) {
        if (KEYS_TO_PARSE.includes(key)) {
            data[key] = cmp[key];
        }
    }

    return data;
}

const gridifyComponent = (cmp) => {
    let componentGridArea = {}
    const rowHeight = 10; // row height in pixels
    const columnWidth = 23; // column width in pixels. not exact and for testing this is only for 1920x1080 res window content width by columns rounded (1100px / 48)
    for (let key in cmp) {
        if (KEYS_TO_PARSE.includes(key)) {
            switch (key) {
                case 'x': {
                    componentGridArea.rowStart = cmp[key] + 1;
                    break;
                }
                case 'y': {
                    componentGridArea.colStart = cmp[key] + 1;
                    break;
                }
                case 'w': {
                    componentGridArea.colEnd = cmp[key] + 1;
                    break;
                }
                case 'h': {
                    componentGridArea.rowEnd = cmp[key];
                    break;
                }
            }
        }
    }
    return `${componentGridArea.rowStart} / ${componentGridArea.colStart} / ${componentGridArea.rowEnd} / ${componentGridArea.colEnd}`;
}

function Layout(props) {
    const [enableSave, setEnableSave] = useState(false);
    const dispatch = useDispatch()


    const { showGrid, darkMode, room, editorDispatch, isEditor, activeItem, view, editLocks, slug, elementClick, preventCollision, setClearBackground, clearBackground } = props;
    const editing = true;
    const { width, height, ref } = useResizeDetector({
        handleHeight: false,
        refreshMode: 'debounce',
        refreshRate: 10,
        onResize: (width, height) => { }
    });

    useEffect(() => {
        dispatch({ type: 'HANDLE_EDITOR_CONTENT_REF', payload: ref })
    }, [ref])

    const elementList = room.components[view].map((cmp, i) => {
        const cProps = { activeItem, cmp, isEditor, elementClick, slug, editorDispatch, view, editLocks, room, editing }
        return <div key={cmp.i} style={isEditor ? { boxShadow: '2px 2px 5px #131313' } : { gridArea: gridifyComponent(cmp) }}>
            <ComponentRenderer {...cProps} />
        </div>
    })
    elementList.push(<div key={'bottom'} data-grid={{ x: 0, y: 1000, w: 48, h: 1, static: false }} style={{ background: '#000' }}></div>)


    function onLayoutChange(layout, allLayouts) {
        if (enableSave) {
            let newRoom = cloneDeep(room);
            let newComponents = [];

            for (let comp of newRoom.components[view]) {
                const newCmpParams = layout.find(c => c.i === comp.i);
                if (newCmpParams) {
                    let newComp = comp;
                    newComp.h = newCmpParams.h;
                    newComp.w = newCmpParams.w;
                    newComp.x = newCmpParams.x;
                    newComp.y = newCmpParams.y;
                    newComponents.push(newComp)
                }
            }
            newRoom.components[view] = newComponents;
            editorDispatch({ type: 'EDITOR_LAYOUT_CHANGE', payload: newRoom, changed: editing })
            editorDispatch({ type: "PUSH_TO_HISTORY", payload: { room: newRoom, label: { text: "Changed layout", time: new Date().getTime() } } });
            setEnableSave(false)
        }

    }

    return <div ref={ref}>
        <Helmet>
            <link rel="stylesheet" type="text/css" href={`${HOST}api/v1/events/${slug}/lobby/style.css/`} />
        </Helmet>
        <div className={`editor-grid ${showGrid ? 'active' : ''} ${view}`} /* style={darkMode ? {mixBlendMode: "overlay"} : null} */></div>
        <ResponsiveGridLayout
            className={`${view} ${isEditor ? 'editor' : ''}`}
            onLayoutChange={onLayoutChange}
            onDragStart={() => setEnableSave(true)}
            onResizeStart={() => setEnableSave(true)}
            layouts={room.components}
            width={width}
            breakpoints={{ desktop: 1119, tablet: 711, mobile: 0 }}
            cols={{ desktop: LAYOUT_COLUMNS, tablet: LAYOUT_COLUMNS, mobile: LAYOUT_COLUMNS }}
            isDraggable={isEditor}
            isResizable={isEditor}
            measureBeforeMount={true}
            rowHeight={10}
            compactType={null}
            preventCollision={preventCollision}
            useCSSTransforms={true}
            containerPadding={[0, 0]}
            resizeHandles={isEditor ? ['e', 'se', 's'] : []}
            margin={[0, 0]}
        >
            {elementList}
        </ResponsiveGridLayout>
    </div>
}


export default Layout;